<template>
  <ServicesDescription>
    <template #title>
      <h2>Onlo Phone</h2>
    </template>
    <template #body>
      <p>
        OnLo Phone - приложение для компьютера для удобного и эффективного управления звонками.
      </p>
      <span>
        Скачайте приложение на свое устройство с операционной системой Windows 7 или Windows 10 -
        <a
          href="https://api.onlinelogic.ru/OnloPhoneService/DownloadOnloPhone"
          :class="$style.downloadLink"
        >
          ссылка на скачивание приложения.
        </a>
      </span>
    </template>
    <template #footer>
      <span>
        Инструкцию по установке можно
        <router-link to="./docs/OnLo_Phone_Faq.pdf" target="_blank" :class="$style.downloadLink">
          прочитать
        </router-link>
        или
        <a
          href="https://www.youtube.com/watch?v=bAEhU92N8EQ"
          :class="$style.downloadLink"
          target="_blank"
        >
          посмотреть
        </a>
        .
      </span>
    </template>
  </ServicesDescription>
</template>

<script>
import ServicesDescription from '@/components/shared/ServicesDescription.vue';

export default {
  components: {
    ServicesDescription
  }
};
</script>

<style module>
.downloadLink {
  font-weight: 700;
}
</style>
